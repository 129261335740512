import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import RoadmapContent from "./RoadmapContent";

function Roadmap() {
  return (
    <Container fluid className="roadmap-section" id="roadmap">
      <Container>
        <Row className="roadmap">
          <Col md={6} className="roadmap-left">
            <h3 className="roadmap-title">PHASE 1</h3>
            <RoadmapContent
              title="WEBSITE AND SOCIAL MEDIA RELEASE ✔"
              content={[]}
            />
            <RoadmapContent title="SMART CONTRACT AUDITS ✔" content={[]} />
            <RoadmapContent title="KYC CERTIFICATE ✔" content={[]} />
            <RoadmapContent title="MASS MARKETING CAMPAIGN ✔" content={[]} />
            <h3 className="roadmap-title">PHASE 2</h3>
            <RoadmapContent
              title="SHIBO TOKEN LAUNCH ON UNISWAP"
              content={[]}
            />
            <RoadmapContent title="MASS MARKETING CAMPAIGN" content={[]} />
            <RoadmapContent
              title="COINMARKETCAP/COINGECKO TRACKING"
              content={[]}
            />
          </Col>
          <Col md={6} className="roadmap-right">
            <h3 className="roadmap-title">PHASE 3</h3>
            <RoadmapContent title="WEBSITE 2.0" content={[]} />
            <RoadmapContent title="LISTING ON EXCHANGES" content={[]} />
            <RoadmapContent title="MINING DAPP LAUNCH" content={[]} />
            <RoadmapContent
              title="DEVELOPMENT APP FOR ANDROID AND IOS"
              content={[]}
            />
            <RoadmapContent
              title="VERIFIED MARKETCAP ON CMC AND CGK"
              content={[]}
            />
            <h3 className="roadmap-title">PHASE 4</h3>
            <RoadmapContent title="LAUNCH OF SHIBO'S MOBILE APP" content={[]} />
            <RoadmapContent title="MASS MARKETING CAMPAIGN" content={[]} />
            <RoadmapContent title="LAUNCH OF SHIBO'S BLOCKCHAIN" content={[]} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Roadmap;
