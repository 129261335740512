import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import logo from '../Assets/main/logo.png';

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener('scroll', scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed='top'
      expand='md'
      className={navColour ? 'sticky' : 'navbar'}
    >
      <Container>
        <Navbar.Brand href='/'>
          <img src={logo} alt='home-img' className='logo' />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          onClick={() => {
            updateExpanded(expand ? false : 'expanded');
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='ml-auto' defaultActiveKey='#home'>
            <Nav.Item>
              <Nav.Link href='#home' onClick={() => updateExpanded(false)}>
                <span>Home</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='#tokenomics'>
                <span>Tokenomics</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='#roadmap' onClick={() => updateExpanded(false)}>
                <span>Roadmap</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href='https://www.dextools.io/'
                target='_blank'
                rel='noreferrer'
                onClick={() => updateExpanded(false)}
              >
                <span>Chart</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='#partners' onClick={() => updateExpanded(false)}>
                <span>Partners</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href='https://www.dextools.io/'
                target='_blank'
                rel='noreferrer'
                onClick={() => updateExpanded(false)}
              >
                <button className='button'>Buy</button>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
