import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import aboutLogo from "../../Assets/main/about.png";

function About() {
  return (
    <Container fluid className="about-section" id="about">
      <span className="about-text">
        Something about <span className="color">Shibo</span>.
      </span>
      <div className="about-content">
        <Row className="about-top">
          <Col>
            <p className="about-text2">
              WHAT IS <span className="color">Shibo</span> ?
            </p>
            <p className="about-text6">
              <span className="color">Shibo</span> is a special meme project,
              all projects named Shibo own millions of dollars in marketcap.
              Promising to be a major project leading the Base Chain trend for a
              long time to come. We will develop the ability to Stake{" "}
              <span className="color">$shibo</span> tokens for investors, and
              especially the ability to mine to earn tokens
            </p>
          </Col>
          <Col>
            <img className="imgAbout" width="200" src={aboutLogo}></img>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default About;
