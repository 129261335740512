import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import About from "./about";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";
import iconTw from "../../Assets/sup/x.png";
import iconTl from "../../Assets/sup/tl.png";
import iconDex from "../../Assets/sup/dex.png";
import iconBsc from "../../Assets/sup/base.png";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <div className="home-content">
          <div className="home-content-top">
            <p className="home-text">
              WELCOME TO <span className="color">Shibo on Base</span> MEME
              ALPHA!
            </p>
            <p className="home-text3">
              Official <span className="color">$shibo</span> Contract
            </p>
            <p className="home-text4"></p>
          </div>
          <div className="home-social">
            <div className="home-social-top">
              <a
                href="https://x.com/SHIBOBASE"
                rel="noreferrer"
                target="_blank"
                className="home-socials">
                <img src={iconTw} alt="home-img" />
              </a>
              <div className="home-socials">
                <a
                  href="https://t.me/SHIBOBASE                  "
                  rel="noreferrer"
                  target="_blank">
                  <img src={iconTl} alt="home-img" />
                </a>
              </div>
            </div>
            <div className="home-social-bot">
              <div className="home-socials">
                <a
                  href="https://www.dextools.io/"
                  rel="noreferrer"
                  target="_blank">
                  <img src={iconDex} alt="home-img" />
                </a>
              </div>
              <div className="home-socials">
                <a
                  href="https://basescan.org/"
                  rel="noreferrer"
                  target="_blank">
                  <img src={iconBsc} alt="home-img" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <About />
        <Tokenomics />
        <Roadmap />
      </Container>
    </section>
  );
}

export default Home;
