import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import tokenImg from "../../Assets/main/logo.png";
import tokenImg2 from "../../Assets/main/tokenomics.png";

function Tokenomics() {
  return (
    <Container fluid className="tokenomics-section" id="tokenomics">
      <marquee className="home-text" scrollamount={18}>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
        <span className="color">-----------------------------</span>
        <img src={tokenImg} alt="home-img" width={70} height={70} />
      </marquee>
      <Container>
        <Row className="tokenomics-content">
          <Col>
            <img
              className="imgToken"
              width="350"
              src={tokenImg2}
              alt="home-img"
            />
          </Col>
          <Col>
            <p className="token-text">
              <span className="color">$shibo</span> Tokenomics
            </p>
            <p className="token-text2">
              Our token <span className="color">$shibo</span> will be available
              on the blockchains.{" "}
            </p>
            <ul className="token-text3">
              <h3>💙Tokenomics</h3>
              <li>Supply: 1.000.000.000 </li>
              <li>LP : Locked</li>
              <h3>💙Tax</h3>
              <li>Buy / Sell : 0/0</li>
              <h3>💙Wallet</h3>
              <li>Max Wallet : No</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Tokenomics;
